/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 11:27:58 AM
 */

@import '../../global/bundle.scss';

$shadow: 0 0 em(30px) rgba(121, 144, 221, 0.25);

.section-accounting {
   padding-top: em(106px);
   padding-bottom: em(147px);
   
   @include media-phone {
      padding-bottom: em(100px);
   }
   @include media-extra {
      padding-bottom: em(90px);
      padding-top: em(50px);
   }
}

.section {
   display: flex;
   
   @include media-phone-and-smaller {
      flex-direction: column-reverse;
      align-items: center;
   }

   &__presentation {
      $size: em(857px);

      width: $size;
      height: $size;
      position: relative;
      margin-right: em(137px);
      
      @include media-laptop {
         font-size: 0.8em;
      }
      @include media-tablet {
         font-size: 0.6em;
      }
      @include media-phone-and-smaller {
         margin-right: 0;
         margin-top: 2em;
      }
      @include media-extra {
         font-size: 0.55em;
      }
      @include media-watches {
         font-size: 0.4em;
      }
      
      &::before {
         content: '';
         position: absolute;
         display: block;
         transform: translate(-50%, -50%);
         top: 50%;
         left: 50%;
         width: $size;
         height: $size;
         border-radius: 50%;
         background: transparentize($color-white, 1 - 0.15);
      }
   }

   &__procedures {
      position: absolute;
      width: 100%;
      z-index: 100;

      &-wrapper {
         position: absolute;
         top: em(94px);
         left: em(116px);
         width: em(305px);
         height: em(660.29px);
      }
   }
   &__save-your-procedures {
      position: absolute;
      top: em(197px);
      right: em(72px);
      z-index: 200;
      width: em(274px);
   }

   &__create-procedure {
      position: absolute;
      width: 100%;
      
      &-wrapper {
         position: absolute;
         top: em(41px);
         right: em(85px);
         width: em(300.47px);
         height: em(649.56px);
      }
   }
   &__15-minutes {
      position: absolute;
      bottom: em(170px);
      right: em(-95px);
      box-shadow: $shadow;
      border-radius: em(69.1825px);
      width: em(379px);
   }
   
   &__time-is-up {
      position: absolute;
      width: em(320px);
      bottom: em(20px);
      right: calc(220 / 857 * 100%);
      z-index: 200;
      box-shadow: $shadow;
      border-radius: em(15.1579px);
   }
   
   &__blink {
      &_large {
         position: absolute;
         top: 1em;
         right: 1em;
         transform: translate(50%, -50%);
         z-index: 200;
      }
      &_medium {
         $size: em(150px);

         position: absolute;
         left: em(-110px);
         bottom: em(113px);
         z-index: 200;
         width: $size;
         height: $size;
      }
      &_small {
         $size: em(100px);

         position: absolute;
         right: em(-85px);
         bottom: em(220px);
         z-index: 200;
         width: $size;
         height: $size;
      }
   }
   
   &__shadow {
      position: absolute;
      width: em(426.54px);
      height: em(382.72px);
      left: em(486px);
      top: em(490px);
      z-index: 300;

      &::after {
         content: '';
         top: 0; left: 0; right: 0; bottom: 0;
         position: absolute;
         transform: rotate(-3.49deg);
         background: rgba(255, 255, 255, 0.1);
         border-radius: 50%;
      }
   }

   &__content {
      width: em(626px);
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      @include media-phone {
         width: em(479px);
         
         > * {
            width: em(626px);
         }
      }
      @include media-extra {
         width: 100%;
      }
      
      p {
         margin: 0;
         padding: 0;
         margin-bottom: 1em;
      }
   }
   &__title {
      margin-bottom: em(56px, 50px);
      @include media-extra {
         font-size: em(35px);
      }
      @include media-watches {
         font-size: em(20px, 14px);
      }
   }
}

.feature {
   display: flex;
   align-items: center;
   margin-top: em(22px);
   
   &__icon {
      margin-right: em(22px);
      display: flex;

      svg {
         @include media-watches {
            width: em(35px, 14px);
            height: em(35px, 14px);
         }
      }
   }
}