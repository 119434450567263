/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-16, 2:47:34 AM
 */

@import '../../global/bundle.scss';

.section-footer {
   height: em(1100px);
   
   @include media-extra {
      height: auto;
      padding-bottom: em(49px);
   }
}

.section {
   position: relative;

   &__content {
      position: absolute;
      right: 0;
      top: em(181px);
      width: fit-content;
      
      @include media-laptop-and-smaller {
         top: em(181px - 40px);
      }
      @include media-extra {
         position: relative;
         top: 0;
         padding-top: em(49px);
         display: flex;
         flex-direction: column;
         align-items: center;
      }
   }

   &__title, &__subtitle {
      color: #FDA5AA;
      text-align: center;
   }
   &__title {
      font-size: em(45px);
      line-height: em(57px, 45px);
      
      @include media-phone {
         font-size: em(40px);
      }
      @include media-extra {
         font-size: em(35px);
         line-height: em(42px, 35px);
         margin-top: em(17px, 25px);
         margin-bottom: em(35px, 35px);
      }
   }
   &__subtitle {
      font-size: em(30px);
      line-height: em(40px, 30px);
      
      @include media-extra {
         font-size: em(25px);
         line-height: em(34px, 25px);
      }
   }

   &__logo {
      width: em(295px);
      height: auto;
      margin: auto;
      
      @include media-watches {
         width: calc(100% - 2em * 2);
      }
      
      svg {
         width: 100%;
         height: auto;
      }
   }

   &__get-confident-link{
      margin-top: 35px;
      display: flex;
      text-decoration-line: underline;
      justify-content: flex-end;
   }

   &__get-agree-link{
      margin-top: 35px;
      display: flex;
      text-decoration-line: underline;
      justify-content: flex-end;
      margin-right: em(72px);
   }
   
   &__get-app-links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: em(80px);
      
      @include media-extra {
         padding-top: em(37px);
      }
      
      a, a:link {
         display: flex;
         margin-right: 0;
         margin-bottom: 0;
         
         img {
            @include media-watches {
               width: 100%;
               height: auto;
            }
         }
      }

      > *:nth-child(3) {
         padding-right: 10px;
      }
   }
   

   &__presentation {
      position: absolute;
      top: em(216px);
      right: em(367px);
      
      @include media-laptop-and-smaller {
         top: em(216px - 40px);
      }
      @include media-extra {
         display: none;
      }
      
      &-mobile {
         @include media-phone-and-larger {
            display: none;
         }
         @include media-watches {
            width: 100%;
         }
      }
   }



}