/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-16, 12:31:50 AM
 */

@import '../../global/bundle.scss';

.section-programs {
   @include media-extra {
      padding-bottom: em(49px);
   }
}

.section {
   @include media-extra {
      display: flex;
      flex-direction: column;
   }

   &__title {
      text-align: center;
      position: relative;
      z-index: 200;
      
      @include media-extra {
         font-size: em(35px);
      }
      @include media-watches {
         font-size: em(20px, 14px);
      }
   }
   &__text {
      text-align: center;
      margin-top: em(47px);
      margin-bottom: em(35px);
      position: relative;
      z-index: 200;
      
      @include media-extra {
         margin-bottom: 0;
         margin-top: em(35px);
      }
   }
   &__buttons {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 200;
      
      @include media-phone-and-smaller {
         flex-direction: column;
      }
      @include media-extra {
         order: 3;
      }
   }
   &__button {
      width: 100%;
      height: 100%;

      &-wrapper {
         position: relative;
         width: em(327px);
         line-height: em(28px);
         margin-right: em(75px);
         
         @include media-phone-and-smaller {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0.75em;
         }
         
         @include media-extra {
            height: em(75px);
         }

         &:last-child {
            margin-right: 0;
         }
      }
      &-blink {
         $size: em(75px);

         position: absolute;
         top: 0.25em;
         left: 0.25em;
         transform: translate(-50%, -50%);
         width: $size;
         height: $size;
         z-index: 300;
      }
   }
   
   &__presentation {
      width: 100%;
      height: em(756px);
      position: relative;
      z-index: 100;
      
      @include media-extra {
         order: 2;
         height: em(500px);
      }
   }
   &__collage {
      position: absolute;
      right: em(91px);
      top: em(-540px);
      width: em(1777px + 782px);
      
      @include media-phone {
         right: em(-557px);
      }
      @include media-extra {
         display: none;
      }
   }
   &__collage-mobile {
      position: absolute;
      left: em(-411px);
      top: em(-50px);
      width: em(1376px);
      right: em(-1017px * 0.8);
      
      @include media-phone-and-larger {
         display: none;
      }
   }
   &__face {
      position: absolute;
      right: em(-186px - 483px);
      top: em(-241px);
      
      @include media-phone-and-smaller {
         display: none;
      }
   }
}