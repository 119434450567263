@import './global/bundle.scss';

html {
   font-size: 20px;
   
   @include media-watches {
      font-size: 15px;
   }
}

.App {
   overflow: hidden;
}

body {
   margin: 0;
   font-family: $font-montserrat;
   font-style: normal;
   font-weight: 500;
   font-size: em(20px);
   max-width: 100%;
   overflow: hidden auto;
   line-height: em(35px, 20px);
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.block {
   height: 2em;
   background-color: grey;
   border: 3px solid black;
}