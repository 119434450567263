@import '../../global/bundle.scss';

$shadow: 0 em(4px) em(20px) transparentize($color-black, 1 - 0.25);


.sections-articles {
  position: relative;
  z-index: 100;

  padding-bottom: 2em;



  @include media-tablet-and-larger {
    transform: translateX(4rem);
    padding-bottom: 5em;
  }



}



.section {
  display: flex;




  &__blink {
    $size: em(40px);

    position: absolute;
    top: 1em;
    right: 1em;
    width: $size;
    height: $size;
    transform: translate(50%, -50%);


    &_small {
      $size: em(150px);

      position: absolute;
      top: em(260px);
      left: em(-10px);
      transform: translateX(-100%);
      width: $size;
      height: $size;
    }
    &_medium {
      $size: em(200px);

      position: absolute;
      top: em(-80px);
      left: em(400px);
      transform: translateX(-100%);
      width: $size;
      height: $size;
    }
    &_last {
      $size: em(140px);

      position: absolute;
      top: em(640px);
      left: em(150px);
      transform: translateX(-100%);
      width: $size;
      height: $size;
    }



    //&-small_schema {
    //  $size: em(150px);
    //
    //  position: absolute;
    //  top: em(403px);
    //  right: em(-50px);
    //  transform: translateX(100%);
    //  width: $size;
    //  height: $size;
    //}
  }





  &__presentation {
    position: relative;

    @include media-laptop {
      font-size: 0.8em;
    }
    @include media-tablet {
      margin-top: 20px;
      font-size: 0.5em;
    }
    @include media-extra {
      margin-top: 20px;
      font-size: 0.55em !important;
      //transform: translateX(-8em);
    }
    @include media-watches {
      font-size: 0.5em !important;
      margin-top: 20px;
      transform: translateX(3.5em);
    }


    @media screen and (max-width: 320px){
      transform: translateX(-1.6em);
    }

  }

  &__shadow {
    position: absolute;

    @include media-laptop {
      font-size: 0.8em;
    }

    @include media-phone-and-smaller {
      display: none;
    }
  }
  &__title {

    @include media-watches {
          font-size: em(20px, 14px);
        }

      @include media-extra {
        font-size: em(35px);
        text-align: inherit;
      }


  }
  &__content {
    position: relative;
    z-index: 1000;

    width: em(650px);

    ul {
      list-style: none;


      li {
        font-size: 20px;
        display: flex;
        justify-content: start;
        align-items: start;
        p {
          margin-right: 40px;
        }

      }
    }




  }






  &_reviews {
    position: relative;
    justify-content: space-between;

    @include media-phone-and-smaller {
      flex-direction: column;
    }

    //.section__shadow {
    //  left: 0;
    //  bottom: em(100px);
    //
    //  @include media-tablet {
    //    display: none;
    //  }
    //
    //  svg {
    //    width: em(816px);
    //    height: auto;
    //  }
    //}
    .section__presentation {
      width: em(1061px - 239px);

      @include media-tablet {
        margin-top: 5em;
        padding-bottom: 2em;
      }
      @include media-phone-and-smaller {
        font-size: 0.7em;
        margin-left: 0;
        padding-bottom: 2em;
      }

      svg {
        margin-right: em(-239px);
      }
    }
    .section__title {
      margin-bottom: em(47px, 50px);

      &_mobile {
        margin-top: 0.75em;
      }
    }
    .section__content {
      padding-top: em(50px);
      margin-right: 100px;

      @include media-phone-and-smaller {
        padding-right: 0rem;
        padding-top: em(12px);
        width: em(400px);
        ul {
          padding-left: 0.1rem;
          li {
            font-size: 15px;
          }
        }
        padding-bottom: em(30px);
      }

      @media screen and (max-width: 459px){
        width: em(350px);
      }
      @media screen and (max-width: 394px){
        width: em(400px);
      }

      @media screen and (max-width: 320px){
        width: em(300px);
      }


      @include media-tablet {
        width: em(400px);
      }



      p {
        margin: 0 0 0 10px;
        //margin-bottom: em(31px);
        padding-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }




    &__articlaFirst {
      width: em(323px);

      &-wrapper {
        position: absolute;
        top: em(165px);
        left: em(149px);
        z-index: 100;
      }
    }
    &__articlaSecond {
      width: em(283px);

      &-wrapper {
        position: absolute;
        top: em(-70px);
        left: em(350px);
        z-index: 100;
      }

      //position: absolute;
      //top: em(15px);
      //left: em(-185px);

      //@include media-watches {
      //  left: -7.25em;
      //}
    }


    &__blink {
      $size: em(40px);

      position: absolute;
      top: 1em;
      right: 1em;
      width: $size;
      height: $size;
      transform: translate(50%, -50%);


      &-small_cosmo-pass {
        $size: em(130px);

        position: absolute;
        top: em(450px);
        left: em(-10px);
        transform: translateX(-100%);
        width: $size;
        height: $size;
      }
      &-small_schema {
        $size: em(150px);

        position: absolute;
        top: em(403px);
        right: em(-50px);
        transform: translateX(100%);
        width: $size;
        height: $size;
      }
    }

    &__schema {
      width: em(310px);

      &-wrapper {
        position: absolute;
        top: em(70px);
        right: 0;
      }
    }
    &__circle-eye {
      position: absolute;
      bottom: em(155px);
      right: em(-11px);
      box-shadow: $shadow;
      border-radius: 50%;
      width: em(82px);
    }
    &__circle-derma {
      position: absolute;
      top: em(300px);
      left: em(-18px);
      box-shadow: $shadow;
      border-radius: 50%;
      width: em(77px);
    }
  }


}