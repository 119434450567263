/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 3:01:57 PM
 * Company: frity corp.
 */
 
@import '../../global/bundle.scss';

.button {
   appearance: none;
   border-radius: 1em;
   border: 1px solid rgba(255, 255, 255, 0.6);
   color: #000;
   cursor: pointer;
   font: inherit;
   font-size: em(18px);
   outline: none;
   padding: em(14px) em(26px);
   background: radial-gradient(107.52% 105.76% at 0% -0.91%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
   backdrop-filter: blur(#{em(42px)});
}
