@mixin media-watches() {
   @media screen and (max-width: #{$device-padded_extra - 1px}) {
      @content;
   }
}
@mixin media-extra() {
   @media screen and (max-width: #{$device-padded_phone - 1px}) {
      @content;
   }
}
@mixin media-phone() {
   @media screen and (min-width: $device-padded_phone) and (max-width: #{$device-padded_tablet - 1px}) {
      @content;
   }
}
@mixin media-tablet() {
   @media screen and (min-width: $device-padded_tablet) and (max-width: #{$device-padded_laptop - 1px}) {
      @content;
   }
}
@mixin media-laptop() {
   @media screen and (min-width: $device-padded_laptop) and (max-width: #{$device-padded_pc - 1px}) {
      @content;
   }
}
@mixin media-pc() {
   @media screen and (min-width: $device-padded_pc) {
      @content;
   }
}
      

@mixin media-extra-and-smaller() {
   @media screen and (max-width: #{$device-padded_phone - 1px}) {
      @content;
   }
}
@mixin media-phone-and-smaller() {
   @media screen and (max-width: #{$device-padded_tablet - 1px}) {
      @content;
   }
}
@mixin media-tablet-and-smaller() {
   @media screen and (max-width: #{$device-padded_laptop - 1px}) {
      @content;
   }
}
@mixin media-laptop-and-smaller() {
   @media screen and (max-width: #{$device-padded_pc - 1px}) {
      @content;
   }
}
@mixin media-pc-and-smaller() {
   @content;
}
      

@mixin media-extra-and-larger() {
   @content;
}
@mixin media-phone-and-larger() {
   @media screen and (min-width: $device-padded_phone) {
      @content;
   }
}
@mixin media-tablet-and-larger() {
   @media screen and (min-width: $device-padded_tablet) {
      @content;
   }
}
@mixin media-laptop-and-larger() {
   @media screen and (min-width: $device-padded_laptop) {
      @content;
   }
}
@mixin media-pc-and-larger() {
   @media screen and (min-width: $device-padded_pc) {
      @content;
   }
}