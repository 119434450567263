/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-14, 12:33:40 PM
 */

.image {
   img {
      display: block;
      margin: 0;
      padding: 0;
   }
}