/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 1:33:50 PM
 * Company: frity corp.
 */

@keyframes blow {
   0% {
      opacity: 0.5;
      transform: scale(0.9);
   }
   50% {
      opacity: 1;
      transform: scale(1);
   }
   100% {
      opacity: 0.5;
      transform: scale(0.9);
   }
}

.blink {
   svg {
      width: 100%;
      height: 100%;
   }
}

.glow {
   transform-origin: center;
   animation: blow 2s linear infinite;
}

.ray {
   transform-origin: center;
   animation: blow 1s linear infinite;
   &_large {
      &_horiz, &_vert {
         animation-delay: 0s;
      }
   }
   &_medium {
      &_lt-rb, &_rt-lb {
         animation-delay: 0.25s;
      }
   }
   &_small {
      &_rrt-llb, &_ltt-rbb {
         animation-delay: 0.5s;
      }
   }
   &_extra {
      &_rtt-lbb, &_llt-rrb {
         animation-delay: 0.75s;
      }
   }
}

.star {
   transform-origin: 56% 45%;
   animation: blow 2s linear infinite;
}

.dot {}