$font-montserrat: 'Montserrat', Arial, Roboto, Ubuntu, OpenSans, 'PT-Sans', Calibri, sans-serif;
$font-times-new-roman: 'Times New Roman', 'Fira Code', Georgia, 'Playfair Display', Didot, Times, serif;

$device-width_watches: 15.625 * 16px;
$device-width_extra: 21 * 16px;
$device-width_phone: 43 * 16px;
$device-width_tablet: 62 * 16px;
$device-width_laptop: 82 * 16px;
$device-width_pc: 102 * 16px;

$device-padding: 2 * 16px;
$device-padding-2: $device-padding * 2;

$device-padded_watches: $device-width_watches + $device-padding-2;
$device-padded_extra: $device-width_extra + $device-padding-2;
$device-padded_phone: $device-width_phone + $device-padding-2;
$device-padded_tablet: $device-width_tablet + $device-padding-2;
$device-padded_laptop: $device-width_laptop + $device-padding-2;
$device-padded_pc: $device-width_pc + $device-padding-2;

$color-black: #000000;
$color-white: #ffffff;