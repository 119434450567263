/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 6:00:20 PM
 */

@import '../../global/bundle.scss';

.section-care {
   position: relative;
   padding-top: em(49px);
   margin-bottom: em(284px);
   
   @include media-tablet {
      margin-bottom: 7em;
   }
   @include media-phone-and-smaller {
      margin-bottom: 5em;
   }
   @include media-extra-and-smaller {
      background: linear-gradient(56.99deg, #F2F7FD 2.09%, #BEE4FF 62.51%, #7990DD 144.9%);
   }
   @include media-extra {
      margin-bottom: 3.5em;
   }
}

.header {
   display: flex;
   justify-content: space-between;
   
   @include media-phone-and-smaller {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      
      &-button {
         margin-top: 1em;
      }
   }
   
   &__logo {
      @include media-watches {
         display: flex;
         justify-content: center;
      }

      svg {
         @include media-watches {
            width: em(185px, 15px);
            height: auto;
         }
      }
   }
   
   &__button {
      @include media-watches {
         display: none;
      }
   }
}

.background {
   position: absolute;
   bottom: -10em;
   left: 50%;
   height: 150vh;
   z-index: 100;
   transform: translateX(-50%);
   overflow: hidden;
   width: 100%;

   svg {
      min-width: 100%;
      height: 100%;
   }
   
   @media screen and (min-width: 2500px) {
      width: 100%;
      height: auto;
   }
   @include media-tablet-and-smaller {
      top: 0;
      bottom: auto;
   }
   @include media-phone {
      height: 200vh;
   }
   @include media-extra-and-smaller {
      display: none;
   }
}

.content-wrapper {
   position: relative;
   z-index: 200;
}

.content {
   display: flex;
   
   @include media-phone-and-smaller {
      flex-direction: column;
      align-items: center;
   }
   
   .get-app-links {
      margin-left: -1em;
      
      @include media-phone-and-smaller {
         display: flex;
         justify-content: center;
         margin-left: 0;
         flex-wrap: wrap;
      }
      @include media-extra-and-smaller {
         font-size: 0.5em;

         > a, a:link {
            display: flex;

            img {
               width: 100%;
            }
         }
      }
      @include media-watches {
         font-size: 0.3em;
      }
      
      > *:first-child {
         margin-right: em(0px);
         
         @include media-extra-and-smaller {
            margin-right: em(7px);
         }
      }
      > *:nth-child(3) {
         margin-left: em(10px);
         
         @include media-extra-and-smaller {
            margin-right: em(7px);
         }
      }
      
      &_pc {
         @include media-extra-and-smaller { display: none; }
      }
      
      &_mobile {
         @include media-phone-and-larger { display: none; }
      }
   }
}

.text {
   flex: 1;
   margin-right: em(63px);
   
   @include media-phone-and-smaller {
      width: min(100%, #{em(583px - 20px * 2)});
      margin-right: 0;
      font-weight: 500;
      font-size: em(15px, 15px);
      line-height: em(25px, 15px);
      text-align: center;
   }
   
   .title {
      margin-bottom: em(45px, 50px);
      margin-top: em(63px, 50px);
      
      @include media-phone {
         font-size: em(40px);
      }
      @include media-extra {
         font-size: em(35px);
      }
      @include media-watches {
         font-size: em(20px, 15px);
         line-height: em(30px, 20px);
         text-align: center;
      }
   }

   .paragraph {
      color: $color-black;
      font-weight: 500;
      line-height: em(35px);
      margin-bottom: em(33px);
      width: em(604px);
      
      @include media-phone-and-smaller {
         width: auto;
      }
      @include media-extra-and-smaller {
         margin-bottom: 0;
      }
   }
}

.presentation {
   flex: 1;
   position: relative;
   
   @include media-phone {
      flex-basis: em(720px);
      width: 100%;
      margin-top: 3em;
   }
   @include media-extra-and-smaller {
      margin-top: em(46px, 15px);
      margin-bottom: em(35px, 15px);
      flex-basis: auto;
      height: min(129 / 296 * $device-width_phone, 129 / 296 * (100vw - 5.5em) * (278 / 129)); // design_width / design_adaptive_screen_width * real_adaptive_screen_width, design_width / design_adaptive_screen_width * real_adaptive_screen_width_via_vw * width_to_height_factor
      width: 100%;
   }
   
   &__frame {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 30px 20px 70px rgba(137, 177, 255, 0.15);
      border-radius: em(44.4444px);
      
      > * img {
         @include media-extra-and-smaller {
            width: 100%;
         }
      }

      &:nth-child(1) {
         left: 0;
         z-index: 300;
         
         @include media-extra-and-smaller {
            top: 50%;
            left: 50%;
            width: calc(129 / 296 * 100%);
            transform: translate(-50%, -50%);
         }
      }
      &:nth-child(2) {
         left: calc(55%);
         transform: translate(-50%, -50%);
         z-index: 200;
         
         @include media-extra-and-smaller {
            left: calc(28 / 296 * 100%);
            width: calc(108 / 296 * 100%);
            transform: translateY(-50%);
         }
      }
      &:nth-child(3) {
         right: 0;
         z-index: 100;
         
         @include media-extra-and-smaller {
            right: calc(28 / 296 * 100%);
            width: calc(108 / 296 * 100%);
            transform: translateY(-50%);
         }
      }
   }
   
   .glare {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      z-index: 400;
      
      > * {
         position: absolute;
         transform: translate(-50%, -50%);
         
         left: 50%;
         top: 50%;
      }

      *:nth-child(1) {
         top: 0;
         left: 1em;
         
         @include media-phone-and-smaller {
            top: 4em;
            left: 8em;
         }
      }
      *:nth-child(2) {
         $size: em(116px);

         top: auto;
         bottom: 8%;
         right: -2em;
         left: auto;
         transform: translate(50%, -50%);
         height: $size;
         width: $size;
         
         @include media-phone-and-smaller {
            right: 3em;
         }
      }
      *:nth-child(3) {
         $size: em(79px);

         top: auto;
         bottom: 0%;
         left: em(-104px);
         transform: translate(-50%, -50%);
         height: $size;
         width: $size;
         
         @include media-laptop-and-smaller {
            left: -3em;
         }
         
         @include media-phone-and-smaller {
            left: 2em;
         }
      }
   }
}

.email-mobile {
   font-size: 0.8em;
   margin-top: em(18px, 15px * 0.8);
   margin-bottom: em(18px, 15px * 0.8);

   @include media-phone-and-larger {
      display: none;
   }
}

.dots {
   position: absolute;
   top: 0; left: 0; right: 0; bottom: 0;
   z-index: 500;
   pointer-events: none;
   font-size: 0.8em;
   opacity: 0.7;
}

.dot {
   background: transparentize($color-white, 1 - 0.1);
   border-radius: 50%;
   position: absolute;

   @keyframes section-care--fly-lt-rb {
      0% { transform: translate(-10%, -10%); }
      50% { transform: translate(10%, 10%); }
      100% { transform: translate(-10%, -10%); }
   }

   @keyframes section-care--fly-lb-rt {
      0% { transform: translate(-10%, 10%); }
      50% { transform: translate(10%, -10%); }
      100% { transform: translate(-10%, 10%); }
   }
   
   &_animation {
      &_lb-rt {
         animation: section-care--fly-lb-rt ease 5s infinite;
      }
      &_lt-rb {
         animation: section-care--fly-lt-rb ease 5s infinite;
      }
   }
   
   &-t-c {
      $size: em(309.65px);
      width: $size;
      height: $size;
      
      top: em(-81px);
      left: calc(694 / 1920 * 100%);
   }
   &-t-cr {
      $size: em(357.48px);
      width: $size;
      height: $size;
      
      top: em(-74px);
      left: calc(905 / 1920 * 100%);
      
      animation-delay: 0.5s;
   }
   &-t-crr {
      $size: em(198px);
      width: $size;
      height: $size;
      
      top: em(-44px);
      right: calc(597 / 1920 * 100%);
      
      animation-delay: 0.75s;
   }
   &-t-r {
      $size: em(253.46px);
      width: $size;
      height: $size;
      
      top: em(-40px);
      right: calc(0 / 1920 * 100%);
   }
   &-t-rr {
      $size: em(259.12px);
      width: $size;
      height: $size;
      
      top: em(71px);
      right: calc(-184 / 1920 * 100%);
      
      animation-delay: 0.25s;
   }

   &-b-lll {
      $size: em(627.68px);
      width: $size;
      height: $size;
      
      bottom: em(-433px);
      left: calc(-181 / 1920 * 100%);
   }
   &-b-ll {
      $size: em(479.43px);
      width: $size;
      height: $size;
      
      bottom: em(-450px);
      left: calc(132 / 1920 * 100%);
      
      animation-delay: 0.25s;
   }
   &-b-l {
      $size: em(876.36px);
      width: $size;
      height: $size;
      
      bottom: em(-557px);
      left: calc(132 / 1920 * 100%);
      
      animation-delay: 0.5s;
   }
}