/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 2:52:26 PM
 */

@import '../../global/bundle.scss';

$shadow: em(21.9977px) em(21.9977px) em(21.9977px) rgba(121, 144, 221, 0.29);

.section-screens {
   padding-bottom: em(329px);
   
   @include media-tablet {
      padding-bottom: em(250px);
   }
   @include media-phone {
      padding-bottom: em(120px);
   }
   @include media-extra {
      padding-bottom: em(80px);
   }
}

.section {
   display: flex;
   position: relative;
   
   @include media-phone-and-smaller {
      flex-direction: column;
      padding-top: em(722px);
      align-items: center;
   }
   @include media-extra {
      padding-top: em(722px * 0.8);
   }
   @include media-watches {
      padding-top: em(722px * 0.6);
   }
   @include media-phone-and-smaller {
      [data-aos][data-aos][data-aos-delay="700"]:global(.aos-animate) {
         transition-delay: 0.2s;
      }
      [data-aos][data-aos][data-aos-delay="800"]:global(.aos-animate) {
         transition-delay: 0.3s;
      }
      [data-aos][data-aos][data-aos-delay="900"]:global(.aos-animate) {
         transition-delay: 0.4s;
      }
      [data-aos][data-aos][data-aos-delay="1400"]:global(.aos-animate) {
         transition-delay: 0.2s;
      }
      [data-aos][data-aos][data-aos-delay="1500"]:global(.aos-animate) {
         transition-delay: 0.3s;
      }
   }
   
   p {
      margin: 0;
      padding: 0;
   }
   
   &__downloads, &__main, &__news {
      position: relative;
      z-index: 100;
   }
   &__downloads, &__news {
      padding-top: em(88px);
      
      @include media-pc {
         flex-basis: calc(50% - #{em(336px / 2)});
      }
      @include media-laptop {
         flex-basis: calc(50% - #{em(316px / 2)});
      }
      @include media-tablet-and-larger {
         flex-basis: calc(50% - #{em(253px / 2)});
      }
      @include media-tablet {
         padding-top: em(60px);
      }
      @include media-phone-and-smaller {
         padding-top: em(60px);
      }
   }

   &__downloads {
      p {
         max-width: em(512px);
         padding-right: 2.5em;
         
         @include media-phone-and-smaller {
            width: em(400px);
            padding-right: 0;
         }
         @include media-extra {
            width: auto;
         }
      }
      
      &-presentation {
         width: em(376px);
         position: relative;
         margin-left: em(15px);
         margin-top: em(30px);
         
         @include media-laptop {
            font-size: 0.8em;
         }
         @include media-tablet {
            font-size: 0.7em;
            margin-left: 0;
         }
         @include media-phone-and-smaller {
            height: em(420px);
         }
         @include media-extra {
            font-size: 0.8em;
         }
      }
   }
   &__download-image {
      border-radius: em(9px);
      box-shadow: $shadow;
      width: em(173px);
      margin-right: em(8px);
      position: absolute;
      top: em(29px);
      left: 0;
      z-index: 100;
   }
   &__gallery {
      border-radius: em(16px);
      box-shadow: $shadow;
      width: em(195px);
      position: absolute;
      top: 0;
      right: 0;
   }
   &__smile-soap {
      box-shadow: em(10px) em(10px) em(20px) rgba(121, 144, 221, 0.25);
      border-radius: em(12.1967px);
      width: em(124px);
      position: absolute;
      top: em(297px);
      right: em(101px);
      z-index: 200;
   }

   &__main {
      width: em(316px);
      height: em(724px);
      
      @include media-tablet {
         font-size: 0.8em;
      }
      @include media-phone-and-smaller {
         position: absolute;
         top: 0;
         left: 50%;
         transform: translate(-50%, 0%);
      }
      @include media-extra {
         font-size: 0.8em;
      }
      @include media-watches {
         font-size: 0.6em;
      }
   }
   &__main-page {
      position: absolute;
      width: em(316px);
      box-shadow: 0 em(4px) em(10px) #A8CAF5;
      border-radius: em(32px);
   }
   &__open-season {
      position: absolute;
      right: em(-42px);
      bottom: em(47px);
      width: em(336px);
   }
   &__social-gallery {
      position: absolute;
      left: em(-48px);
      top: em(377px);
      width: em(261px);
   }
   
   &__arrow-to-downloads {
      position: absolute;
      left: em(-28px);
      top: em(359px);
      width: em(163px);
      transform: translate(-100%, -85%);

      &[data-aos^=fade][data-aos^=fade]:global(.aos-animate) {
         transform: translate(-100%, -85%) translateZ(0);
      }
      
      @include media-laptop {
         font-size: 0.8em;
         top: em(370px, 20px * 0.8);
      }
      @include media-tablet {
         font-size: 0.8em;
         top: em(390px, 20px * 0.8);
         transform-origin: 100% 75%;
         
         &[data-aos^=fade][data-aos^=fade]:global(.aos-animate) {
            transform: translate(-100%, -85%) translateZ(0) rotate(16deg);
         }
      }
      @include media-phone-and-smaller {
         display: none;
      }
   }
   &__arrow-to-news {
      position: absolute;
      right: em(-70px);
      top: em(644px);
      width: em(164px);
      transform: translate(100%, -50%);
      
      &[data-aos^=fade][data-aos^=fade]:global(.aos-animate) {
         transform: translate(100%, -50%) translateZ(0);
      }
      
      @include media-laptop {
         font-size: 0.8em;
         top: em(600px, 20px * 0.8);
      }
      @include media-tablet {
         font-size: 0.8em;
         top: em(600px, 20px * 0.8);
      }
      @include media-phone-and-smaller {
         display: none;
      }
   }
   &__arrow-mobile {
      position: absolute;
      left: em(-35px);
      top: em(510px);
      transform: translate(-100%, 0);
      width: auto;
      height: em(250px);

      @include media-tablet-and-larger {
         display: none;
      }
   }
   
   &__news {
      position: relative;

      p {
         padding-left: em(23px);
      }
      
      &-content {
         position: absolute;
         right: 0;
         width: em(431px);
         
         @include media-tablet {
            width: auto;
            padding-left: 1.25em;
         }
         @include media-phone-and-smaller {
            position: relative;
         }
         @include media-extra {
            width: auto;
         }
      }
      &-presentation {
         width: em(431px);
         position: relative;
         
         @include media-laptop {
            font-size: 0.9em;
            margin-left: auto;
         }
         @include media-tablet {
            font-size: 0.65em;
            margin-left: auto;
            margin-top: 1em;
         }
         @include media-phone-and-smaller {
            height: em(440px)
         }
         @include media-extra {
            font-size: 0.7em;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1.5em;
         }
      }
   }
   &__news-screen {
      position: absolute;
      top: em(45px);
      left: 0;
      width: em(193px);
      box-shadow: $shadow;
      border-radius: em(16.0065px);
   }
   &__skin-care {
      position: absolute;
      width: em(198px);
      top: em(-4px);
      right: 0;
      box-shadow: $shadow;
      border-radius: em(16.121px);
   }
   
   &__shadow {
      position: absolute;
      bottom: rem(206px);
      left: 50%;
      transform: translate(-50%, 0);
      width: em(1758px);
      font-size: 1vw;
   }
}