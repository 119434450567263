/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-14, 6:07:36 PM
 */

@import '../../global/bundle.scss';

$shadow: 0 em(4px) em(20px) transparentize($color-black, 1 - 0.25);

.sections-mirrored {
   position: relative;
   z-index: 100;
}

.section {
   display: flex;
   
   &__presentation {
      position: relative;

      @include media-laptop {
         font-size: 0.8em;
      }
      @include media-tablet {
         font-size: 0.5em;
      }
      @include media-extra {
         font-size: 0.55em !important;
         transform: translateX(-8em);
      }
      @include media-watches {
         font-size: 0.51em !important;
         transform: translateX(-4.5em);
      }
      @media screen and (max-width: 320px){
         font-size: 0.39em !important;
         transform: translateX(-4.8em);
      }


   }
   &__shadow {
      position: absolute;
      
      @include media-laptop {
         font-size: 0.8em;
      }
      @include media-phone-and-smaller {
         display: none;
      }
   }
   &__title {
      @include media-phone-and-smaller {
         display: none;
      }
      &_mobile {
         text-align: center;
         display: flex;
         justify-content: center;
         margin-bottom: 1em;
         
         @include media-tablet-and-larger {
            display: none;
         }
         @include media-extra {
            font-size: em(40px);
         }
         @include media-watches {
            font-size: em(20px, 14px);
         }
      }
   }
   &__content {
      position: relative;
      z-index: 1000;
      
      p {
         margin: 0;
         margin-bottom: em(31px);
         
         &:last-child {
            margin-bottom: 0;
         }
      }
   }
   &__triple {
      width: em(947px);
      height: auto;
   }
   &__double {
      width: em(1061px);
      height: auto;
   }

   &_pass {
      position: relative;
      margin-bottom: em(57px);
      
      @include media-tablet {
         margin-bottom: 0;
      }
      @include media-phone-and-smaller {
         flex-direction: column;
      }
      
      .section__shadow {
         right: 0;
         top: 0;

         @include media-tablet {
            top: 4em;
         }
         
         svg {
            width: em(1008px);
            height: auto;
         }
      }
      .section__presentation {
         width: em(947px);
         
         @include media-tablet {
            margin-top: 24.5em;
         }
         @include media-phone-and-smaller {
            font-size: 0.7em;
         }
      }
      .section__title {
         margin-bottom: em(63px, 50px);
      }
      .section__content {
         padding-top: em(274px);
         padding-left: em(52px);
         
         @include media-phone-and-smaller {
            padding-top: em(12px);
            padding-left: 0;
         }
      }

      &__gen-review {
         width: em(300px);
         
         &-wrapper {
            position: absolute;
            top: em(115px);
            left: em(138px);
         }
      }
      &__age-group {
         width: em(362px);
         position: absolute;
         top: em(222px);
         left: em(-82px);
         box-shadow: $shadow;
         border-radius: em(7.66021px);
      }
      &__blink {
         position: absolute;
         top: 1em;
         right: 1em;
         transform: translate(50%, -50%);
         
         &-small_reviews {
            $size: em(150px);

            position: absolute;
            top: em(500px);
            left: em(-30px);
            transform: translateX(-100%);
            width: $size;
            height: $size;
         }
         &-small_phototype {
            $size: em(120px);

            position: absolute;
            top: em(219px);
            right: em(-34px);
            transform: translateX(100%);
            width: $size;
            height: $size;
         }
      }

      &__phototype {
         width: em(300px);
         
         &-wrapper {
            position: absolute;
            top: em(222px);
            right: em(153px);
         }
      }
      &__phototype-woman {
         width: em(173px);
         height: em(152px);
         position: absolute;
         top: em(158px);
         right: em(45px);
         //box-shadow: $shadow;
         border-radius: em(16px);
      }

      &__phototype-rccab {
         width: em(327px);
         height: em(128px);
         position: absolute;
         top: em(182px);
         right: em(-40px);
         //box-shadow: $shadow;
         border-radius: em(16px);
      }

      &__phototype-redhead {
         width: em(275px);
         position: absolute;
         top: em(382px);
         right: em(0px);
         //box-shadow: $shadow;
      }

   }

   &_reviews {
      position: relative;
      justify-content: space-between;

      @include media-phone-and-smaller {
         flex-direction: column-reverse;
      }
      
      .section__shadow {
         left: 0;
         bottom: em(100px);
         
         @include media-tablet {
            display: none;
         }
         
         svg {
            width: em(816px);
            height: auto;
         }
      }
      .section__presentation {
         width: em(1061px - 239px);
         margin-left: em(336px);
         
         @include media-tablet {
            margin-top: 12em;
         }
         @include media-phone-and-smaller {
            font-size: 0.7em;
            margin-left: 0;
         }
         
         svg {
            margin-right: em(-239px);
         }
      }
      .section__title {
         margin-bottom: em(47px, 50px);

         &_mobile {
            margin-top: 0.75em;
         }
      }
      .section__content {
         padding-top: em(100px);
         
         @include media-phone-and-smaller {
            padding-top: em(12px);
            padding-bottom: em(40px);
         }
         
         p {
            margin: 0;
            margin-bottom: em(31px);
            
            &:last-child {
               margin-bottom: 0;
            }
         }
      }

      &__cosmo-pass {
         width: em(310px);
         
         &-wrapper {
            position: absolute;
            top: em(165px);
            left: em(149px);
            z-index: 100;
         }
      }
      &__lets-talk {
         position: absolute;
         top: em(15px);
         left: em(-185px);
         width: em(410px);
         
         @include media-watches {
            left: -7.25em;
         }
      }
      &__mimic-wrinkle {
         position: absolute;
         width: em(364px);
         bottom: em(54px);
         right: em(-70px);
         box-shadow: $shadow;
         border-radius: em(7.7037px);
         z-index: 200;
      }
      
      &__blink {
         $size: em(400px);

         position: absolute;
         top: 1em;
         right: 1em;
         width: $size;
         height: $size;
         transform: translate(50%, -50%);
         
         
         &-small_cosmo-pass {
            $size: em(130px);

            position: absolute;
            top: em(450px);
            left: em(-10px);
            transform: translateX(-100%);
            width: $size;
            height: $size;
         }
         &-small_schema {
            $size: em(150px);

            position: absolute;
            top: em(403px);
            right: em(-50px);
            transform: translateX(100%);
            width: $size;
            height: $size;
         }
      }

      &__schema {
         width: em(310px);
         
         &-wrapper {
            position: absolute;
            top: em(70px);
            right: 0;
         }
      }
      &__circle-eye {
         position: absolute;
         bottom: em(155px);
         right: em(-11px);
         box-shadow: $shadow;
         border-radius: 50%;
         width: em(82px);
      }
      &__circle-derma {
         position: absolute;
         top: em(300px);
         left: em(-18px);
         box-shadow: $shadow;
         border-radius: 50%;
         width: em(77px);
      }
   }
}