/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 11:50:55 PM
 */

@import '../../global/bundle.scss';

.title {
   font-family: $font-times-new-roman;
   font-style: normal;
   font-weight: 700;
   font-size: em(50px);
   line-height: em(60px, 50px);
   
   @include media-watches {
      font-size: em(20px, 14px);
   }
}