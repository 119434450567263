/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 5:06:54 PM
 * Company: frity corp.
 */

@import '../../global/bundle.scss';

.adaptive {
   margin: auto;

   @include media-pc-and-smaller {
      width: rem($device-width_pc);
   }
   @include media-laptop-and-smaller {
      width: rem($device-width_laptop);
   }
   @include media-tablet-and-smaller {
      width: rem($device-width_tablet);
   }
   @include media-phone-and-smaller {
      width: rem($device-width_phone);
   }
   @include media-extra-and-smaller {
      width: calc(min(100% - #{$device-padding-2}, #{$device-width_extra}));
   }

   @include media-pc-and-larger {
      width: rem($device-width_pc);
   }
}
