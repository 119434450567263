/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 12:15:41 PM
 */

@import '../../global/bundle.scss';

.background-wave {
   mask-image: url("data:image/svg+xml,%3Csvg width='1920' height='16899' viewBox='0 0 1920 16899' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16899H1920V187.277C1648.5 298.001 1449.48 272.678 1130.64 146.42C520.5 -82.9994 349.5 -20.9993 0 187.277V16899Z' fill='black'/%3E%3C/svg%3E%0A");
   background: linear-gradient(359.4deg, #E3F1FD 0.55%, rgba(168, 216, 255, 0.67) 110.43%);

   @include media-phone {
      padding-top: 50px;
      mask-position: -9em 10%;
   }


   @media screen and (max-width: 751px) {
      mask-position: -9em 10%;
   }

   @include media-tablet {
      padding-top: 20px;
      mask-position: -9em 0%;
   }
   @include media-tablet-and-smaller {
      padding-top: 50px;
      mask-position: -20em 10%;
   }


   @include media-extra {
      mask-position: -4.5em 0%;
   }
   @include media-watches {
      mask-position: -15em 10%;
   }
}